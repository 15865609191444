








import { Component, Prop, Vue } from 'vue-property-decorator';

import { CategoryGetList } from '@/api/select';
@Component({
    name: 'ColumnTypeCard',
    components: {}
})
export default class ColumnTypeCard extends Vue {
    @Prop({
        type: [String, Number],
        default: ''
    })
    private ParentId: string | Number;

    public loading: boolean = false;

    public activeIndex: String = '';

    private params: any = {
        PageIndex: 1,
        PageSize: 20,
        ParentId: '' // 这里的类型id 需要根据总后台管理写死、注意后台添加数据后不可改变 目前只有 8 和 9
    };

    private created() {
        this.init();
    }

    public tabChange(item) {
        this.activeIndex = item.CategoryId;
        this.$emit('change', item.CategoryId);
        this.$emit('changeAll', item);
    }

    public tabData: any = [];

    private async init() {
        try {
            this.loading = true;
            this.params.ParentId = this.ParentId;
            const { DataList } = await CategoryGetList(this.params);
            this.tabData.map((item) => {
                item.CategoryId = String(item.CategoryId);
                return item;
            });
            this.tabData = DataList || [];
            if (this.tabData.length) {
                this.tabChange(this.tabData[0]);
            }
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    public empty() {
        this.activeIndex = '';
    }
}
