











































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
    name: 'CommodityList',
    components: {}
})
export default class CommodityList extends Vue {
    @Prop({
        type: Array,
        default: () => []
    })
    public listData: any;

    public open(item) {
        window.open(item.Url);
    }
}
