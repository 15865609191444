


























import { Component, Ref, Vue } from 'vue-property-decorator';

import YwhzList from './component/list.vue';
import YwhzskeletonList from './component/skeleton-list.vue';
import ColumnTypeCard from '@/components/PortalSite/ColumnTypeCard/index.vue';

import { ProductGetList } from '@/api/api';

@Component({
    name: 'Commodity',
    components: {
        ColumnTypeCard,
        YwhzskeletonList,
        YwhzList
    }
})
export default class Commodity extends Vue {
    @Ref('ColumnTypeCard') private readonly ColumnTypeCard:any

    public listData: any = [];
    public loading: boolean = false;

    public SearchStringKey: any = '';

    public params: any = {
        StringKey: '', // 搜索内容
        LongPageNo: 1,
        LongPageSize: 44
    }

    public change(item) {
        this.params.StringKey = item.CategoryName;
        this.init();
    }

    public async init() {
        try {
            this.loading = true;
            const { Data } = await ProductGetList(this.params);
            this.listData = Data;
        } catch (err) {
            console.warn(err);
        } finally {
            this.loading = false;
        }
    }

    public searchChange() {
        this.params.StringKey = this.SearchStringKey;
        this.ColumnTypeCard.empty();
        this.init();
    }

    public TurnPages(type) {
        if (type === 'top') {
            this.params.LongPageNo--;
        }

        if (type === 'bottom') {
            this.params.LongPageNo++;
        }
        this.init();
    }
}
